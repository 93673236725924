import { createGlobalState } from "@vueuse/core";
import { reactive, ref as refVue } from "vue";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  getDocs,
  getDoc,
  updateDoc,
  query,
  where,
  Unsubscribe
} from "firebase/firestore";
import { db } from "@/services/firebase/Instance";
import _ from "lodash";
import { useEventStore } from "@/stores/event";
import eventDatabase from "~/services/database/event";

export const useEvent = createGlobalState(() => {
  // state
  const eventStore = useEventStore()
  const eventId = refVue(eventStore.eventId); 

  return {
    getEvent,
    checkEventAlreadyExists,
    fetchRealtimeEvent,  
    insertEvent,
    updateEvent,
    setUpdate,
    getEventByPairCode,
    updateEventUser,
    convertUserIdToEventId,
    removeFirstPage
  };

  async function getEvent(_eventId:string){    
    return await eventDatabase().get(_eventId)
  }

  async function checkEventAlreadyExists(eventId: string){
    const isAlreadyExits = await eventDatabase().get(eventId)
    if(!isAlreadyExits){
      return false
    }

    return true
  }

  async function insertEvent(_eventId:string, payload: any){
    const newEventId = _eventId?.toUpperCase()
    await eventDatabase()._create(newEventId, payload)
  }

  function fetchRealtimeEvent(_eventId:string, callback:Function):Unsubscribe { 
    const unsubscribe = eventDatabase()._realtimeFetch(_eventId || eventId.value, (event)=>{
      callback(event)
    })

    return unsubscribe
  }

  async function updateEvent(_eventId:string, payload: any) {
    await eventDatabase()._update(_eventId || eventId.value, payload)
  }

  async function setUpdate(_eventId:string, payload: any) {
    await eventDatabase()._set(_eventId || eventId.value, payload)
  }

  async function updateEventUser(_eventId:string, payload: any) {
    await eventDatabase()._set(_eventId || eventId.value, payload)
  }  

  async function removeFirstPage(_eventId:string, field: string) {
    await eventDatabase()._arrayRemoveFirst(_eventId || eventId.value, field)
  } 

  async function getEventByPairCode(pairCode:string) {
    return await eventDatabase().getEventByPairCode(pairCode)
  }

  async function convertUserIdToEventId  (userId, lengthNumber = 6)  {
    return userId.substring(Math.max(userId.length - lengthNumber, 0));
  };

});
