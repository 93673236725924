import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'th',
  messages: {}
})

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(i18n)
})

export {
  i18n
}