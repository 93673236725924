import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import {  getAuth } from 'firebase/auth'
import { getStorage } from "firebase/storage"

import { config } from './config'

// Initialize Firebase
const app = initializeApp(config);
const storage = getStorage(app)
const db = getFirestore(app)
const auth = getAuth()
const firebase = getApp()
console.log("init firebase.")

export {
    app,
    db,
    auth, 
    firebase,
    storage
}

