
import useUser from "~/composables/useUser";
import { useAuthStore } from "@/stores/auth";
import { useEventStore } from "@/stores/event";
import _ from 'lodash'
import { useLogStore } from "@/stores/log";
import dayjs from "dayjs";
import useDefaultField from "@/composables/useDefaultField"

export const useUserStore = defineStore("user", {
  state: () => ({
    users: [],
    phoneNumber: ''
  }),
  getters: {
    displayUserList(state) {
      const eventStore = useEventStore()
      const authStore = useAuthStore()
      const list = this.users || []
      let filteredOnlyUsers = _.filter(list, u => u?.admin !== true)

      if(eventStore?.isRealOwner) {
        filteredOnlyUsers = _.concat(authStore?.user, filteredOnlyUsers)
      }

      filteredOnlyUsers = _.uniqBy(filteredOnlyUsers, u => u?.uid)
      return filteredOnlyUsers
    }

  },
  actions: {
    async setNewUser(userId:string, payload:any): Promise<void>{    
      await useUser().setNewUser(userId, payload)
      
      // ---- set log -----
      const logStore = useLogStore()  
      await logStore.createNewLogUser(userId, payload)
    }, 
    async setMergeUser(userId:string, payload:any): Promise<void>{    
      await useUser().setMergeUser(userId, payload)      
    }, 
    async getUserById(userId:string): Promise<void>{      
      return await useUser().getUserByLineId(userId)
    },   
    async getUserByPhone(phoneNumber: string): Promise<any> {
      return new Promise((resolve, reject) => {
        const _unsubScribe = useUser().getUserByPhone(phoneNumber, (_user) => {
          resolve({ _user, _unsubScribe });
        });
      });
    },     
    async updateUserEvent(userId:string, payload:any): Promise<void>{      
      return await useUser().updateUserEvent(userId, payload)
    }, 
    async setUserFromPartner() {
      const authStore = useAuthStore();
      const eventStore = useEventStore();  
      const lineProfile = { ...authStore.line };
      const dataModel = useDefaultField()
      const eventId = eventStore.eventId 

      console.log("new User Event ID", eventId)
      const newUserPayload = {
        ...dataModel.user.register(lineProfile ,eventId)        
      };

      const isAlreadyEvent = await eventStore.checkEventAlready(eventId);
      if (!isAlreadyEvent) {
        const eventPayload = {
          ...dataModel.event.register(lineProfile ,eventId),  
          //package: "pro",
        }

        await eventStore.createEvent(eventId, eventPayload);
        console.log("created event")
      }else{     
        //await eventStore.updateEvent({package:"pro"});
        console.log("updated event")
      }
      
      //check if user is already
      const user = await this.getUserById(lineProfile.userId);
      let userPayload = {}

      if(!user){       
        userPayload = newUserPayload    
        await this.setNewUser(lineProfile.userId, {...userPayload, fromPartner:true});   
      }

      console.log("user payload=>",userPayload)
    }, 
    async setInviteUser(inviteMode: string) {
      const authStore = useAuthStore();
      const eventStore = useEventStore();
      const appStateStore = useAppStateStore()

      const eventId = localStorage.getItem("invite_eventId");
      const lineProfile = { ...authStore.line };
      const inviteModeLocal = localStorage.getItem("invite_mode");

      if (!eventId) {
        return;
      }

      const isAlreadyEvent = await eventStore.checkEventAlready(eventId);
      if (!isAlreadyEvent) {
        alert("Event not found");
        appStateStore.setShowMainModal(true)
        // navigateTo("/register");
        return;
      }
      
      //check if user is already
      const user = await this.getUserById(lineProfile.userId);
      const targetMode = inviteMode ? inviteMode : inviteModeLocal ? inviteModeLocal : 'viewer'

      const newUserPayload = {
        displayName: lineProfile.displayName,
        firstName: lineProfile.displayName,
        lastName: "",
        phoneNumber: "",       
        admin: false,
        photowishTeam: false,
        email: "",
        line: lineProfile,
        uid: lineProfile.userId,
        eventId: eventId,
        eventList: [eventId],
        photoURL: lineProfile.pictureUrl || "",     
      };

      let userPayload = {}

      if(user){        
        let eventList = [...user.eventList]
        eventList.push(eventId)
        eventList = _.uniq(eventList)

        userPayload = {
          ...user, 
          invite: true, 
          inviteMode: targetMode || 'viewer' ,
          eventId: eventId, 
          eventList:eventList,
        }
      }else{
        userPayload = {
          ...newUserPayload,
          invite: true,
          inviteMode: targetMode || 'viewer' 
        }
      }


      await this.setNewUser(lineProfile.userId, userPayload);
      localStorage.removeItem("invite_eventId");
      localStorage.removeItem("invite_mode");

      return userPayload
    }, 
    async getUsersByEventId() {
      const eventStore = useEventStore()
      const eventId = eventStore?.eventId

      if(!eventId) {
        return 
      }

      console.log('eventId --->', eventId)

      return new Promise((resolve, reject) => {
        const _unsubScribe = useUser().getUsersByEventId(eventId, (_user) => {
          // console.log(_user)
          this.users = _user
          resolve(true);
        });
      });
    },
    async deleteUserAccount(userId: string) {
      return await useUser().deleteUser(userId)
    },

   
  },
});
