import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  signInWithCustomToken,
  signOut,
  onAuthStateChanged,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  User,
} from "firebase/auth";
import { app, firebase } from "@/services/firebase/Instance";
import { delay } from "@/utils/delay";

const useSignIn = () => {
  const auth = getAuth(app);
  auth.languageCode = "th";
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account" });

  const signInWithGmail = (): Promise<never> => {
    return signInWithRedirect(auth, provider);
  };

  const signInWithToken = async (token: string) => {
    return signInWithCustomToken(auth, token);
  };

  const signOutAuth = () => {
    return signOut(auth);
  };

  const currentUser = (): Promise<User> => {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        resolve(user);
      });
    });
  };

  // signin phone number
  const useSignInPhoneNumber = () => {
    const initRecaptchaVerifer = async (idRecaptchaDiv: string) => {
      setTimeout(() => {
        window.windowRecapt = {};
        window.windowRecapt.recaptchaVerifier = new RecaptchaVerifier(
          idRecaptchaDiv,
          {
            size: "invisible",
            callback: (response: any) => {},
          },
          auth
        );
        window.windowRecapt.recaptchaVerifier.render();
      }, 1000 * 0.5);
    };

    const sendOtp = async (phoneNumber: string): Promise<void> => {
      const appVerifier = window.windowRecapt.recaptchaVerifier;
      phoneNumber = `+66${phoneNumber.substring(1, 10)}`;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      window.windowRecapt.confirmationResult = confirmationResult;

      return;
    };

    const signIn = async (otpCode: string) => {
      await window.windowRecapt.confirmationResult.confirm(otpCode);
    };

    return {
      initRecaptchaVerifer,
      sendOtp,
      signIn,
    };
  };

  return {
    signInWithGmail,
    signInWithToken,

    signOutAuth,
    currentUser,

    useSignInPhoneNumber,
  };
};

export default useSignIn;
