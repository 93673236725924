export default {
  "blank-default": () => import("/Users/rice/photowish-connect-liff/layouts/blank-default.vue").then(m => m.default || m),
  blank: () => import("/Users/rice/photowish-connect-liff/layouts/blank.vue").then(m => m.default || m),
  brand: () => import("/Users/rice/photowish-connect-liff/layouts/brand.vue").then(m => m.default || m),
  checkout: () => import("/Users/rice/photowish-connect-liff/layouts/checkout.vue").then(m => m.default || m),
  default: () => import("/Users/rice/photowish-connect-liff/layouts/default.vue").then(m => m.default || m),
  desktop: () => import("/Users/rice/photowish-connect-liff/layouts/desktop.vue").then(m => m.default || m),
  events: () => import("/Users/rice/photowish-connect-liff/layouts/events.vue").then(m => m.default || m),
  home: () => import("/Users/rice/photowish-connect-liff/layouts/home.vue").then(m => m.default || m),
  invite: () => import("/Users/rice/photowish-connect-liff/layouts/invite.vue").then(m => m.default || m),
  login: () => import("/Users/rice/photowish-connect-liff/layouts/login.vue").then(m => m.default || m),
  "partner-register": () => import("/Users/rice/photowish-connect-liff/layouts/partner-register.vue").then(m => m.default || m),
  partner: () => import("/Users/rice/photowish-connect-liff/layouts/partner.vue").then(m => m.default || m)
}