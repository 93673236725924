import promptpayDatabase from "~/services/database/promptpay";
import { useEventStore } from "@/stores/event"
import { genExamplePromptpay } from '../utils/genExampleData'

export const usePromptpayStore = defineStore("promptpay", {
  state: () => ({
    promptpay:[],
    unsubScribe: null,
  }),
  getters: {
    getPromptpayList(state) {
      return state.promptpay.filter(p=>p.status =="success")
    }
  },
  actions: {
    async fetchPromptpayRealtime() {
      const eventStore = useEventStore()
      const unsubScribe = await promptpayDatabase()._realtimeFetch(eventStore.eventId, (promptpay)=>{
        this.promptpay = promptpay
      })  
      
      this.unsubScribe = unsubScribe
    },
    async removePromptpayById(promptpayId:string) {
      const eventStore = useEventStore()
      await promptpayDatabase()._remove(eventStore.eventId , promptpayId)    
    },
    unSubscribePromptpay() {
      if (!this.unsubScribe) return;
      this.unsubScribe();
    },

    // --- example ---
    async createExamplePromptpay(eventId: string) {
      if(!eventId) {
        return 
      }

      const list = genExamplePromptpay(eventId)
      const promiseList = []

      for (let index = 0; index < list.length; index++) {
        const promptpay = list[index];
        const promptpayId = promptpay?.promptpayId
        console.log(promptpayId, promptpay)
        promiseList.push(promptpayDatabase()._set(eventId, promptpayId, promptpay))
      }

      await Promise.all(promiseList)
      console.log('create example wishes success')
    }
  },
});
