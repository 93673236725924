import { default as registerScb2VQXfshMeta } from "/Users/rice/photowish-connect-liff/pages/auth/register.vue?macro=true";
import { default as validatewdX69wqr0MMeta } from "/Users/rice/photowish-connect-liff/pages/auth/validate.vue?macro=true";
import { default as brand16XMlsB5l1Meta } from "/Users/rice/photowish-connect-liff/pages/brand.vue?macro=true";
import { default as contactEsKg8JraTDMeta } from "/Users/rice/photowish-connect-liff/pages/contact.vue?macro=true";
import { default as eventInfokEqERmhp76Meta } from "/Users/rice/photowish-connect-liff/pages/eventInfo.vue?macro=true";
import { default as eventskCEd2aipkYMeta } from "/Users/rice/photowish-connect-liff/pages/events.vue?macro=true";
import { default as faq9nVDNM0MG4Meta } from "/Users/rice/photowish-connect-liff/pages/faq.vue?macro=true";
import { default as indexJzgYgmutoxMeta } from "/Users/rice/photowish-connect-liff/pages/index.vue?macro=true";
import { default as invitetKcjnQRwRFMeta } from "/Users/rice/photowish-connect-liff/pages/invite.vue?macro=true";
import { default as loginFTkF8IDx7TMeta } from "/Users/rice/photowish-connect-liff/pages/login.vue?macro=true";
import { default as mainfqubxNcoF6Meta } from "/Users/rice/photowish-connect-liff/pages/main.vue?macro=true";
import { default as notifyJmjliOES5SMeta } from "/Users/rice/photowish-connect-liff/pages/notify.vue?macro=true";
import { default as checkoutT9qI4v5VVfMeta } from "/Users/rice/photowish-connect-liff/pages/package/checkout.vue?macro=true";
import { default as priceb2do0gecBuMeta } from "/Users/rice/photowish-connect-liff/pages/package/price.vue?macro=true";
import { default as pairEventmKJIniwIzCMeta } from "/Users/rice/photowish-connect-liff/pages/pairEvent.vue?macro=true";
import { default as pairOrder11jHHG4GRpMeta } from "/Users/rice/photowish-connect-liff/pages/pairOrder.vue?macro=true";
import { default as checkoutVyk0SmJnyOMeta } from "/Users/rice/photowish-connect-liff/pages/partner/checkout.vue?macro=true";
import { default as indexA4bKcRlGJVMeta } from "/Users/rice/photowish-connect-liff/pages/partner/index.vue?macro=true";
import { default as profileUntUgUAUANMeta } from "/Users/rice/photowish-connect-liff/pages/profile.vue?macro=true";
import { default as promptpayZbXSL7XPt1Meta } from "/Users/rice/photowish-connect-liff/pages/promptpay.vue?macro=true";
import { default as qrcodepdyOaMwKf7Meta } from "/Users/rice/photowish-connect-liff/pages/qrcode.vue?macro=true";
import { default as sharepsNG6SUc69Meta } from "/Users/rice/photowish-connect-liff/pages/share.vue?macro=true";
import { default as shippingcnmaKszZ7hMeta } from "/Users/rice/photowish-connect-liff/pages/shipping.vue?macro=true";
import { default as sortcpFCK459ysMeta } from "/Users/rice/photowish-connect-liff/pages/sort.vue?macro=true";
import { default as templatesYWmA6XEV9dMeta } from "/Users/rice/photowish-connect-liff/pages/templates.vue?macro=true";
import { default as test7hRVmfYWNOMeta } from "/Users/rice/photowish-connect-liff/pages/test.vue?macro=true";
import { default as uploadbJ5poo68yOMeta } from "/Users/rice/photowish-connect-liff/pages/upload.vue?macro=true";
import { default as favoriteoL3yIeBlt5Meta } from "/Users/rice/photowish-connect-liff/pages/v2/brand/favorite.vue?macro=true";
import { default as indexH2LN4WSgwnMeta } from "/Users/rice/photowish-connect-liff/pages/v2/brand/index.vue?macro=true";
import { default as wishes5dD6k5FRnrMeta } from "/Users/rice/photowish-connect-liff/pages/v2/brand/wishes.vue?macro=true";
import { default as indexF66kl2FzYYMeta } from "/Users/rice/photowish-connect-liff/pages/v2/contact/index.vue?macro=true";
import { default as sortCKNJvk1VB6Meta } from "/Users/rice/photowish-connect-liff/pages/v2/desktop/sort.vue?macro=true";
import { default as homelFhQwDQcA8Meta } from "/Users/rice/photowish-connect-liff/pages/v2/home.vue?macro=true";
import { default as indexJmX0WQTQxjMeta } from "/Users/rice/photowish-connect-liff/pages/v2/index.vue?macro=true";
import { default as liff12nPwRVy3JhMeta } from "/Users/rice/photowish-connect-liff/pages/v2/liff1.vue?macro=true";
import { default as indexDU32jt2wLTMeta } from "/Users/rice/photowish-connect-liff/pages/v2/lineNotify/index.vue?macro=true";
import { default as main3DxoA0TfdGMeta } from "/Users/rice/photowish-connect-liff/pages/v2/lineNotify/main.vue?macro=true";
import { default as faqsNzHm3lWGwHMeta } from "/Users/rice/photowish-connect-liff/pages/v2/promptpay/faqs.vue?macro=true";
import { default as howToUsegO93hpD5UNMeta } from "/Users/rice/photowish-connect-liff/pages/v2/promptpay/howToUse.vue?macro=true";
import { default as index_45design_452kqdvLH00PaMeta } from "/Users/rice/photowish-connect-liff/pages/v2/promptpay/index-design-2.vue?macro=true";
import { default as indexSjSqn98YKLMeta } from "/Users/rice/photowish-connect-liff/pages/v2/promptpay/index.vue?macro=true";
import { default as mainQ29lSaDpJtMeta } from "/Users/rice/photowish-connect-liff/pages/v2/promptpay/main.vue?macro=true";
import { default as qrcodeAndLinklOuStZpXRKMeta } from "/Users/rice/photowish-connect-liff/pages/v2/promptpay/qrcodeAndLink.vue?macro=true";
import { default as faqs4OFGdemVeDMeta } from "/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/faqs.vue?macro=true";
import { default as howToUsex89kVxmBVnMeta } from "/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/howToUse.vue?macro=true";
import { default as index_46design_452cmU65mL4r5Meta } from "/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/index.design-2.vue?macro=true";
import { default as indexyFEKmXmmJJMeta } from "/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/index.vue?macro=true";
import { default as linkI0f2Bmus4vMeta } from "/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/link.vue?macro=true";
import { default as main3MAfyECG0TMeta } from "/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/main.vue?macro=true";
import { default as photosCQL40W1LcuMeta } from "/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/photos.vue?macro=true";
import { default as registerjwi94bpMt3Meta } from "/Users/rice/photowish-connect-liff/pages/v2/register.vue?macro=true";
import { default as indexgiu6doR1MxMeta } from "/Users/rice/photowish-connect-liff/pages/v2/setting/index.vue?macro=true";
import { default as mainp1XjSi4qTfMeta } from "/Users/rice/photowish-connect-liff/pages/v2/setting/main.vue?macro=true";
import { default as shippingAndPromptpaysNqkQvKMS4Meta } from "/Users/rice/photowish-connect-liff/pages/v2/shippingAndPromptpay.vue?macro=true";
import { default as indexWhAHZE97KCMeta } from "/Users/rice/photowish-connect-liff/pages/v2/users/index.vue?macro=true";
import { default as maine1nsPAhopMMeta } from "/Users/rice/photowish-connect-liff/pages/v2/users/main.vue?macro=true";
import { default as faqsmSeea5iTMcMeta } from "/Users/rice/photowish-connect-liff/pages/v2/wish/faqs.vue?macro=true";
import { default as howToUseIZ3XNASB10Meta } from "/Users/rice/photowish-connect-liff/pages/v2/wish/howToUse.vue?macro=true";
import { default as index_45design_452WdWHQecs1GMeta } from "/Users/rice/photowish-connect-liff/pages/v2/wish/index-design-2.vue?macro=true";
import { default as indexbd2FJYVYMSMeta } from "/Users/rice/photowish-connect-liff/pages/v2/wish/index.vue?macro=true";
import { default as mainWBBWfbOSkHMeta } from "/Users/rice/photowish-connect-liff/pages/v2/wish/main.vue?macro=true";
import { default as qrcodeAndLinkKLNLFIeSQdMeta } from "/Users/rice/photowish-connect-liff/pages/v2/wish/qrcodeAndLink.vue?macro=true";
import { default as templatesWnBMafcaTVMeta } from "/Users/rice/photowish-connect-liff/pages/v2/wish/templates.vue?macro=true";
import { default as sortInfo497YdMDQ7oMeta } from "/Users/rice/photowish-connect-liff/pages/v2/wishes/sortInfo.vue?macro=true";
import { default as indexdJrjxWu4LgMeta } from "/Users/rice/photowish-connect-liff/pages/wish/desktop/index.vue?macro=true";
import { default as InfoWkEXXf6WDIMeta } from "/Users/rice/photowish-connect-liff/pages/wish/desktop/Info.vue?macro=true";
import { default as loginUgBqpSRmBCMeta } from "/Users/rice/photowish-connect-liff/pages/wish/desktop/login.vue?macro=true";
import { default as uploadm9eGRpREiRMeta } from "/Users/rice/photowish-connect-liff/pages/wish/desktop/upload.vue?macro=true";
import { default as prewedding0e71gAqm3EMeta } from "/Users/rice/photowish-connect-liff/pages/wish/prewedding.vue?macro=true";
import { default as wishesaetE71fFBeMeta } from "/Users/rice/photowish-connect-liff/pages/wish/wishes.vue?macro=true";
export default [
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerScb2VQXfshMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate",
    path: "/auth/validate",
    meta: validatewdX69wqr0MMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/auth/validate.vue").then(m => m.default || m)
  },
  {
    name: "brand",
    path: "/brand",
    meta: brand16XMlsB5l1Meta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/brand.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/Users/rice/photowish-connect-liff/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "eventInfo",
    path: "/eventInfo",
    component: () => import("/Users/rice/photowish-connect-liff/pages/eventInfo.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: eventskCEd2aipkYMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/events.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/Users/rice/photowish-connect-liff/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/rice/photowish-connect-liff/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite",
    path: "/invite",
    meta: invitetKcjnQRwRFMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginFTkF8IDx7TMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/Users/rice/photowish-connect-liff/pages/main.vue").then(m => m.default || m)
  },
  {
    name: "notify",
    path: "/notify",
    component: () => import("/Users/rice/photowish-connect-liff/pages/notify.vue").then(m => m.default || m)
  },
  {
    name: "package-checkout",
    path: "/package/checkout",
    meta: checkoutT9qI4v5VVfMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/package/checkout.vue").then(m => m.default || m)
  },
  {
    name: "package-price",
    path: "/package/price",
    meta: priceb2do0gecBuMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/package/price.vue").then(m => m.default || m)
  },
  {
    name: "pairEvent",
    path: "/pairEvent",
    component: () => import("/Users/rice/photowish-connect-liff/pages/pairEvent.vue").then(m => m.default || m)
  },
  {
    name: "pairOrder",
    path: "/pairOrder",
    component: () => import("/Users/rice/photowish-connect-liff/pages/pairOrder.vue").then(m => m.default || m)
  },
  {
    name: "partner-checkout",
    path: "/partner/checkout",
    meta: checkoutVyk0SmJnyOMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/partner/checkout.vue").then(m => m.default || m)
  },
  {
    name: "partner",
    path: "/partner",
    meta: indexA4bKcRlGJVMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/Users/rice/photowish-connect-liff/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "promptpay",
    path: "/promptpay",
    component: () => import("/Users/rice/photowish-connect-liff/pages/promptpay.vue").then(m => m.default || m)
  },
  {
    name: "qrcode",
    path: "/qrcode",
    meta: qrcodepdyOaMwKf7Meta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/qrcode.vue").then(m => m.default || m)
  },
  {
    name: "share",
    path: "/share",
    component: () => import("/Users/rice/photowish-connect-liff/pages/share.vue").then(m => m.default || m)
  },
  {
    name: "shipping",
    path: "/shipping",
    component: () => import("/Users/rice/photowish-connect-liff/pages/shipping.vue").then(m => m.default || m)
  },
  {
    name: "sort",
    path: "/sort",
    meta: sortcpFCK459ysMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/sort.vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/Users/rice/photowish-connect-liff/pages/templates.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    meta: test7hRVmfYWNOMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "upload",
    path: "/upload",
    meta: uploadbJ5poo68yOMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/upload.vue").then(m => m.default || m)
  },
  {
    name: "v2-brand-favorite",
    path: "/v2/brand/favorite",
    meta: favoriteoL3yIeBlt5Meta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/brand/favorite.vue").then(m => m.default || m)
  },
  {
    name: "v2-brand",
    path: "/v2/brand",
    meta: indexH2LN4WSgwnMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/brand/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-brand-wishes",
    path: "/v2/brand/wishes",
    meta: wishes5dD6k5FRnrMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/brand/wishes.vue").then(m => m.default || m)
  },
  {
    name: "v2-contact",
    path: "/v2/contact",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-desktop-sort",
    path: "/v2/desktop/sort",
    meta: sortCKNJvk1VB6Meta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/desktop/sort.vue").then(m => m.default || m)
  },
  {
    name: "v2-home",
    path: "/v2/home",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/home.vue").then(m => m.default || m)
  },
  {
    name: "v2",
    path: "/v2",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-liff1",
    path: "/v2/liff1",
    meta: liff12nPwRVy3JhMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/liff1.vue").then(m => m.default || m)
  },
  {
    name: "v2-lineNotify",
    path: "/v2/lineNotify",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/lineNotify/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-lineNotify-main",
    path: "/v2/lineNotify/main",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/lineNotify/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-faqs",
    path: "/v2/promptpay/faqs",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/promptpay/faqs.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-howToUse",
    path: "/v2/promptpay/howToUse",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/promptpay/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-index-design-2",
    path: "/v2/promptpay/index-design-2",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/promptpay/index-design-2.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay",
    path: "/v2/promptpay",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/promptpay/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-main",
    path: "/v2/promptpay/main",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/promptpay/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-promptpay-qrcodeAndLink",
    path: "/v2/promptpay/qrcodeAndLink",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/promptpay/qrcodeAndLink.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-faqs",
    path: "/v2/realtimeSlides/faqs",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/faqs.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-howToUse",
    path: "/v2/realtimeSlides/howToUse",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-index.design-2",
    path: "/v2/realtimeSlides/index.design-2",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/index.design-2.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides",
    path: "/v2/realtimeSlides",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-link",
    path: "/v2/realtimeSlides/link",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/link.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-main",
    path: "/v2/realtimeSlides/main",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-realtimeSlides-photos",
    path: "/v2/realtimeSlides/photos",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/realtimeSlides/photos.vue").then(m => m.default || m)
  },
  {
    name: "v2-register",
    path: "/v2/register",
    meta: registerjwi94bpMt3Meta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/register.vue").then(m => m.default || m)
  },
  {
    name: "v2-setting",
    path: "/v2/setting",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-setting-main",
    path: "/v2/setting/main",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/setting/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-shippingAndPromptpay",
    path: "/v2/shippingAndPromptpay",
    meta: shippingAndPromptpaysNqkQvKMS4Meta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/shippingAndPromptpay.vue").then(m => m.default || m)
  },
  {
    name: "v2-users",
    path: "/v2/users",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/users/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-users-main",
    path: "/v2/users/main",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/users/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-faqs",
    path: "/v2/wish/faqs",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wish/faqs.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-howToUse",
    path: "/v2/wish/howToUse",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wish/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-index-design-2",
    path: "/v2/wish/index-design-2",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wish/index-design-2.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish",
    path: "/v2/wish",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wish/index.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-main",
    path: "/v2/wish/main",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wish/main.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-qrcodeAndLink",
    path: "/v2/wish/qrcodeAndLink",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wish/qrcodeAndLink.vue").then(m => m.default || m)
  },
  {
    name: "v2-wish-templates",
    path: "/v2/wish/templates",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wish/templates.vue").then(m => m.default || m)
  },
  {
    name: "v2-wishes-sortInfo",
    path: "/v2/wishes/sortInfo",
    component: () => import("/Users/rice/photowish-connect-liff/pages/v2/wishes/sortInfo.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop",
    path: "/wish/desktop",
    meta: indexdJrjxWu4LgMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/wish/desktop/index.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop-Info",
    path: "/wish/desktop/Info",
    component: () => import("/Users/rice/photowish-connect-liff/pages/wish/desktop/Info.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop-login",
    path: "/wish/desktop/login",
    meta: loginUgBqpSRmBCMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/wish/desktop/login.vue").then(m => m.default || m)
  },
  {
    name: "wish-desktop-upload",
    path: "/wish/desktop/upload",
    meta: uploadm9eGRpREiRMeta || {},
    component: () => import("/Users/rice/photowish-connect-liff/pages/wish/desktop/upload.vue").then(m => m.default || m)
  },
  {
    name: "wish-prewedding",
    path: "/wish/prewedding",
    component: () => import("/Users/rice/photowish-connect-liff/pages/wish/prewedding.vue").then(m => m.default || m)
  },
  {
    name: "wish-wishes",
    path: "/wish/wishes",
    component: () => import("/Users/rice/photowish-connect-liff/pages/wish/wishes.vue").then(m => m.default || m)
  }
]