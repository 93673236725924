// const config = {
//     apiKey: "AIzaSyCEwY6eiWrVCCIvZpXO3vZKURZCh0b3HWM",
//     authDomain: "app.photowish.com",
//     databaseURL: "https://wedding-wish-89585.firebaseio.com",
//     projectId: "wedding-wish-89585",
//     storageBucket: "wedding-wish-89585.appspot.com",
//     messagingSenderId: "925246419659",
//     appId: "1:925246419659:web:8f864dd006b694fe0c614e",
//     measurementId: "G-08VL1SXR4E"
//   }
  
const config = {
  apiKey: "AIzaSyDJjlrMUcTHETMahGo7-iprTGopbs88ZOw",
  authDomain: "photowishv2.firebaseapp.com",
  databaseURL: "https://photowishv2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "photowishv2",
  storageBucket: "photowishv2",
  messagingSenderId: "150081644918",
  appId: "1:150081644918:web:eefba2c870001104cbb257",
  measurementId: "G-J1ZR13D5R7"
}

  export {
    config
  }