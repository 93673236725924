
import useLog from "~/composables/useLog";
import { useAuthStore } from "@/stores/auth";
import { useEventStore } from "@/stores/event";
import _ from 'lodash'

export const useLogStore = defineStore("log", {
  state: () => ({
  }),
  actions: { 
    async createNewLogUser(userId:string, userData: any) {
      const type = 'user'
      const payload = {
        type: type,
        eventId: userData?.eventId || "",
        timestamp: new Date().toISOString(),
        details: {
          ...userData,
          userId: userId
        }
      }
      await useLog().createNewLog(type, payload)
    },

    async createNewLogPayment(transactionData: any) {
      const eventStore = useEventStore()
      const eventId = eventStore?.event?.eventId
      const type = 'payment'
      const payload = {
        type: type,
        eventId: eventId,
        timestamp: new Date().toISOString(),
        details: {
          ...transactionData,
        }
      }
      await useLog().createNewLog(type, payload)
    },

  },
  getters: {

  },
});
