export const useAppStateStore = defineStore("appState", {
  state: () => ({  
    menuSelected:"Photo Wish",
    loading: false,
    toast: {      
      isShow: false,
      text: '',
      type: 'success',  
      timeout: 3000,
      clearTime: null,
    },
    isDesktop:false,   
    isShowMainModal:true
  }),
  actions: {
    async useToast({text, type, timeout = 3000}) {
			this.closeToast()
			let toastData = {
				isShow: true,
        type,
				text,
				timeout: timeout || this.toast.timeout,
			}
			this.toast = toastData	

      this.closeToastWithTimeout()		
		},
    setMenuSelected(selected:string){
      this.menuSelected = selected
    },
    setShowMainModal(isShowMainModal:boolean){
      this.isShowMainModal = isShowMainModal
    },
    closeToast(){
      this.toast.isShow = false
    },
		closeToastWithTimeout() {
      setTimeout(()=>{
        this.toast.isShow = false
      },  this.toast.timeout)			
		},  
  }
});

