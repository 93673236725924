import { createGlobalState } from "@vueuse/core";
import { db } from "@/services/firebase/Instance";
import logDatabase from "~/services/database/log";

export default createGlobalState(() => { 
  return {
    createNewLog
  };

  async function createNewLog (type: string, payload: any) {
    console.log('create new log type: ', type)
    return logDatabase()._create(payload)
  }
});
