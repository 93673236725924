import { db } from '@/services/firebase/instance'
import { doc, getDoc, setDoc, query, addDoc, collection, where, onSnapshot, Unsubscribe, deleteDoc } from 'firebase/firestore'
import { User } from '~/interfaces/database/user'

const userDatabase = () => {
  const collectionName = 'users'

  const _get = async (uid: string): Promise<User | null> => {
    const docRef = doc(db, collectionName, uid)
    const docSnap:any = await getDoc(docRef)
    const data = docSnap.data()
    if(data) return { docId: docSnap.id, ...docSnap.data() }
    return null
  }

  const _getWhere = async(field:string , value:string, callback: (user: []) => void): Unsubscribe => { 
    const q = query(
      collection(db, collectionName),
      where(field, "==", value),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let user = [];
        querySnapshot.forEach((doc) => {
            user.push({ docId: doc.id, ...doc.data() });
        });    
       
        callback(user);
      });
    
    return unsubscribe
  }

  const _set = async (userId:string, payload: any) => {
    const docRef = doc(db, collectionName, userId)    
    return setDoc(docRef, payload )   
  }

  const _setAndMerge = async (userId:string, payload: any) => {
    const docRef = doc(db, collectionName, userId)    
    return setDoc(docRef, payload as any, { merge: true })   
  }

  const _create = async (payload: User) => {
    const docRef = collection(db, collectionName)    
    return await addDoc(docRef, payload);    
  }


  const _delete = async (userId: string) => {
    return await deleteDoc(doc(db, collectionName, userId));
  }

  return {
    _get,
    _getWhere,
    _create,
    _set,
    _setAndMerge,
    _delete
  }
}

export default userDatabase