import { db } from '@/services/firebase/instance'
import { doc, getDoc, setDoc, addDoc, updateDoc, onSnapshot, Unsubscribe, deleteField, collection, query, where } from 'firebase/firestore'


const logDatabase = () => {
  const collectionName = 'log'

  const _create = async (payload: Event) => {
    const docRef = await addDoc(collection(db, collectionName), payload);
    return await setDoc(docRef, payload)
  }


  const _set = async (logId: string, payload: Event) => {
    const docRef = doc(db, collectionName, logId)
    return setDoc(docRef, payload as any, { merge: true })
  }
  

  return {
    _create, 
    _set,  
  }
}

export default logDatabase