import revive_payload_client_4sVQNw7RlN from "/Users/rice/photowish-connect-liff/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/rice/photowish-connect-liff/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/rice/photowish-connect-liff/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/rice/photowish-connect-liff/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/rice/photowish-connect-liff/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/rice/photowish-connect-liff/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/rice/photowish-connect-liff/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/rice/photowish-connect-liff/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CWPtG9H9Dx from "/Users/rice/photowish-templates-module-v1/src/runtime/plugin.ts";
import chunk_reload_client_UciE0i6zes from "/Users/rice/photowish-connect-liff/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_VfGcjrvSkj from "/Users/rice/photowish-connect-liff/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/Users/rice/photowish-connect-liff/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_CWPtG9H9Dx,
  chunk_reload_client_UciE0i6zes,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk
]