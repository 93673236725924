import { db } from '@/services/firebase/instance'
import { doc, getDoc, setDoc, updateDoc, onSnapshot, Unsubscribe , collection} from 'firebase/firestore'


const templateDatabase = () => {
  const collectionName = 'templates'

  const get = async (eventId: string): Promise<any | null> => {
    const docRef = doc(db, collectionName, eventId)
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()) {
      return Object.assign({ docId: docSnap.id }, docSnap.data()) as any
    } else {
      return null
    }
  }

  const _create = async (eventId: string, payload: any) => {
    const docRef = doc(db, collectionName, eventId)
    return await setDoc(docRef, payload)
  }
  const _update = async (eventId: string, payload: any) => {
    const docRef = doc(db, collectionName, eventId)
    return updateDoc(docRef, payload as any)
  }
  const _set = async (eventId: string, payload: any) => {
    const docRef = doc(db, collectionName, eventId)
    return setDoc(docRef, payload as any, { merge: true })
  }
  const _realtimeFetch = ( callback: (event: any) => void): Unsubscribe => {
    const unsub = onSnapshot(collection(db, collectionName), (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        docId: doc.id,
        ...doc.data()
      }));
  
      if (data.length === 0) {
        callback(null);
        return;
      }
  
      callback(data);
    });
  
    return unsub;
  };

  return {
    get,
    _create,
    _update,
    _set,
    _realtimeFetch
  }
}

export default templateDatabase