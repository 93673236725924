import _ from 'lodash';
import { makeid } from './makeid'
import { serverTimestamp } from 'firebase/firestore'

function genExamplePromptpay(eventId: string) {

  const mockList = [
    { 
      "id": "promptpay_example_1",
      "promptpayId": "promptpay_example_1",
      "fullName": "นัทนิสา (Example)",
      "friendOf": "groom",
    },
    { 
      "id": "promptpay_example_2",
      "promptpayId": "promptpay_example_2",
      "fullName": "Jintana (Example)",
      "friendOf": "bride",
    },
    { 
      "id": "promptpay_example_3",
      "promptpayId": "promptpay_example_3",
      "fullName": "แพรว (Example)",
      "friendOf": "bride",
    },
    { 
      "id": "promptpay_example_4",
      "promptpayId": "promptpay_example_4",
      "fullName": "พิมพ์ลดา (Example)",
      "friendOf": "groom",
    },
  ]

  const list = _.map(mockList, p => {
    const data = Object.assign({
      "example": true,
      "eventId": eventId,
      "userId": "EXAMPLE_PROMPTPAY",
      "isReaded": true,
      "imageUrl": "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/991fa664-0013-4487-421a-6ae01d468b00/original",
      "remark": "",
      "line": {
        "userPicture": "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/b69c70a9-fe9d-4992-e9a7-37a8d436d900/original",
        "userId": "EXAMPLE_PROMPTPAY"
      },
      "amount": "2000.00",
      "bucket": "photowishv2",
      "createAt": new Date().toISOString(),
      "status": "success",
      "imageType": "URL",
    }, p)
    return data
  })


  return list
}


function genExampleWishes(eventId: string) {
  const exampleWishes = [
    {
      "example": true,
      "eventId": "",
      "userId": "",
      "docId": "",
      "reRender": true,
      "status": "approved",
      "templateId": "standard16",
      "layout": "landscape",

      "wish": {
        "text": "ยินดีและภูมิใจกับเจ้าบ่าวเจ้าสาวมากๆ เป็นคู่ที่เหมาะสมกันที่สุด ขอให้มีความสุขมากๆ นะ",
        "words": [
          "ยินดี",
          "และ",
          "ภูมิใจ",
          "กับ",
          "เจ้าบ่าว",
          "เจ้าสาว",
          "มา",
          "กๆ",
          " ",
          "เป็น",
          "คู่",
          "ที่",
          "เหมาะสม",
          "กัน",
          "ที่สุด",
          " ",
          "ขอ",
          "ให้",
          "มี",
          "ความ",
          "สุ",
          "ขมา",
          "กๆ",
          " ",
          "นะ"
        ]
      },
      "played": true,
      "render": {
        "cfId": "aafa67df-4a49-4d83-829f-c749d6b20000",
      },
      "guest": {
        "imageAttrs": {
          "x": 339.48298988753305,
          "id": "guest",
          "y": 128.35398954418224,
          "skewX": 0,
          "width": 926.9900380999966,
          "offsetX": 0,
          "rotation": 0,
          "height": 617.4216719999977,
          "skewY": 0,
          "scaleY": 1,
          "scaleX": 1,
          "offsetY": 0
        },
        "name": "Ploy Pilin (Example)",
        "image": {
          "cfRemovebgId": "8b4115d5-4ab2-4887-32d0-026078f10a00",
          "cfId": "a02b84c5-8e13-49d3-5e8e-e3156a379700"
        }
      },
    },
    {
      "example": true,
      "eventId": "",
      "userId": "",
      "docId": "",
      "layout": "landscape",
      "wish": {
        "words": [
          "ขอ",
          "ให้",
          "เจ้าบ่าว",
          "เจ้าสาว",
          "ทั้ง",
          " ",
          "2",
          " ",
          "ประสบ",
          "ความ",
          "สุข",
          " ",
          "ความ",
          "สำเร็จ",
          "ใน",
          "ชีวิต",
          "สมรส",
          " ",
          "ชีวิต",
          "ครอบครัว",
          " ",
          "และ",
          "ชีวิต",
          "การ",
          "งาน",
          "ตลอด",
          "ไป"
        ],
        "text": "ขอให้เจ้าบ่าวเจ้าสาวทั้ง 2 ประสบความสุข ความสำเร็จในชีวิตสมรส ชีวิตครอบครัว และชีวิตการงานตลอดไป"
      },
      "status": "approved",
      "templateId": "remove02",
      "guest": {
        "image": {
          "cfId": "e7c42b44-485e-4f98-6852-077654a02200",
          "original": "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/e7c42b44-485e-4f98-6852-077654a02200/original",
          "thumbnail": "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/e7c42b44-485e-4f98-6852-077654a02200/thumbnail",
          "remove_bg": "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/375f5aac-890a-4532-fdef-e49e3ab3a500/original",
          "hd": "https://imagedelivery.net/G-5q2BMDs1WUCh3jAuvl7w/e7c42b44-485e-4f98-6852-077654a02200/hd",
          "cfRemovebgId": "375f5aac-890a-4532-fdef-e49e3ab3a500"
        },
        "imageAttrs": {
          "skewX": 0,
          "skewY": 0,
          "height": 997.8702776749248,
          "x": -3.3220176468313056,
          "id": "guest",
          "offsetX": 0,
          "y": 132.24367777064174,
          "rotation": 0,
          "width": 997.8702776749248,
          "scaleX": 1,
          "scaleY": 1,
          "offsetY": 0
        },
        "name": "นัทนิสา (Example)",
      },

      "played": true,
      "hashId": "YMw",
      "render": {
        "cfId": "70f11ebe-84ae-432b-5db9-97c141557d00",
      }
    },
    {
      "example": true,
      "eventId": "",
      "userId": "",
      "docId": "",
      "layout": "landscape",
      "render": {
        "cfId": "9835b3ab-ee33-4422-bb2c-dceaefd55000",
      },
      "played": true,
      "guest": {
        "imageAttrs": {
          "height": 818.6034999999979,
          "width": 818.6034999999979,
          "skewX": 0,
          "skewY": 0,
          "y": 116.75983766515071,
          "id": "guest",
          "x": -56.94599705350393,
          "offsetX": 0,
          "offsetY": 0,
          "rotation": 0,
          "scaleX": 1.364970748212983,
          "scaleY": 1.364970748212983
        },
        "image": {
          "cfRemovebgId": "71646dfd-4f0e-4680-8d7d-35627791e000",
          "cfId": "8be8f645-389d-40bc-0767-e18c67907100",
        },
        "name": "Pimlada (Example)"
      },
      "templateId": "remove04",
      "status": "approved",
      "hashId": "F9R",
      "wish": {
        "text": "ขอให้เจ้าบ่าวดูแลเจ้าสาวให้ดีๆนะคะ หนักนิดเบาหน่อยขอให้อภัยให้กัน ขอให้ทั้งคู่ประสบความสำเร็จในชีวิต ขอให้รักกันนานๆ",
        "words": [
          "ขอ",
          "ให้",
          "เจ้าบ่าว",
          "ดูแล",
          "เจ้าสาว",
          "ให้",
          "ดีๆ",
          "นะ",
          "คะ",
          " ",
          "หนัก",
          "นิด",
          "เบา",
          "หน่อย",
          "ขอ",
          "ให้",
          "อภัย",
          "ให้",
          "กัน",
          " ",
          "ขอ",
          "ให้",
          "ทั้ง",
          "คู่",
          "ประสบ",
          "ความ",
          "สำเร็จ",
          "ใน",
          "ชีวิต",
          " ",
          "ขอ",
          "ให้",
          "รัก",
          "กัน",
          "นา",
          "นๆ"
        ]
      },
    },
    {
      "example": true,
      "eventId": "",
      "userId": "",
      "docId": "",
      "guest": {
        "imageAttrs": {
          "scaleX": 1,
          "width": 684.4802285062291,
          "scaleY": 1,
          "offsetX": 0,
          "skewY": 0,
          "y": 136.76265446259237,
          "height": 1026.7203427593436,
          "offsetY": 0,
          "skewX": 0,
          "id": "guest",
          "x": 138.3175512435739,
          "rotation": -3.150374693705237
        },
        "image": {
          "cfId": "a5e77e96-e9a7-44ab-9344-12699b0f0d00",
          "cfRemovebgId": "b7ce74ab-4a3c-4322-fe69-57bb29acab00"
        },
        "name": "Kimberry (Example)"
      },
      "templateId": "standard18",
      "played": true,
      "status": "approved",
      "layout": "landscape",
      "hashId": "lAK",
      "render": {
        "cfId": "f897e896-391c-4b66-b301-5393a2bc4600",
      },
      "wish": {
        "text": "เนื่องในวันสำคัญของคู่บ่าวสาวในวันนี้ ซึ่งเป็นวันสำคัญที่สุด ขออวยพรให้มีความสุข รักกันยาวนาน ผิดถูกก็ให้อภัยกัน และรักยืนยงตลอดไป",
        "words": [
          "เนื่อง",
          "ใน",
          "วัน",
          "สำคัญ",
          "ของ",
          "คู่",
          "บ่าว",
          "สาว",
          "ใน",
          "วัน",
          "นี้",
          " ",
          "ซึ่ง",
          "เป็น",
          "วัน",
          "สำคัญ",
          "ที่สุด",
          " ",
          "ขอ",
          "อวยพร",
          "ให้",
          "มี",
          "ความ",
          "สุข",
          " ",
          "รัก",
          "กัน",
          "ยาว",
          "นาน",
          " ",
          "ผิด",
          "ถูก",
          "ก็",
          "ให้",
          "อภัย",
          "กัน",
          " ",
          "และ",
          "รัก",
          "ยืนยง",
          "ตลอด",
          "ไป"
        ]
      },
    }
  ]

  const list = _.map(exampleWishes, (w, index) => {
    const userId = 'EXAMPLE_WISH'
    const hashId = `00${index + 1}`
    return {
      ...w,
      eventId: eventId,
      userId: userId,
      hashId: hashId,
      docId: `${eventId}:${userId}:${hashId}`,
      timestamp: serverTimestamp(),
    }
  })


  return list

}


export {
  genExamplePromptpay,
  genExampleWishes
}