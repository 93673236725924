import { db } from '@/services/firebase/instance'
import { doc, getDoc, setDoc, updateDoc, onSnapshot, Unsubscribe } from 'firebase/firestore'

const appStateDatabase = () => {
  const collectionName = 'appState'

  // template
  const _get = async (eventId: string): Promise<Object | null> => {
    const docId = `${eventId}:templates`
    const docRef = doc(db, collectionName, docId)
    const docSnap = await getDoc(docRef)
    if(docSnap.exists) return { ...docSnap.data() }

    return null
  }

  const _create = async (eventId: string, payload: Object) => {
    const docId = `${eventId}:templates`
    const docRef = doc(db, collectionName, docId)
    return await setDoc(docRef, payload)
  }

  const _update = async (eventId: string, payload: any) => {
    const docId = `${eventId}:templates`
    const docRef = doc(db, collectionName, docId)
    return updateDoc(docRef, payload)
  }

  const _realtimeFetch = (eventId: string, callback): Unsubscribe => {
    const docId = `${eventId}:templates`
    const unsub = onSnapshot(doc(db, collectionName, docId), (doc) => {
      const templateData: Object = doc.data()
      callback(templateData)
    })

    return unsub
  }

  return {
    _get,
    _create,
    _update,
    _realtimeFetch
  }
}

export default appStateDatabase