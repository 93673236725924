import { db } from '@/services/firebase/instance'
import { doc, getDoc, setDoc, addDoc, updateDoc, onSnapshot, Unsubscribe, deleteField, collection } from 'firebase/firestore'


const lineDatabase = () => {
  const collectionName = 'line'

  const get = async (lineId: string): Promise<any | null> => {
    const docRef = doc(db, collectionName, lineId)
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()) {
      return Object.assign({ docId: docSnap.id }, docSnap.data()) as any
    } else {
      return null
    }
  }

  const _create = async (payload: Event) => {
    const docRef = await addDoc(collection(db, collectionName), payload);
    return await setDoc(docRef, payload)
  }


  const _update = async (lineId: string, payload: Event) => {
    const docRef = doc(db, collectionName, lineId)   
    return updateDoc(docRef, payload as any)
  }


  const _set = async (lineId: string, payload: Event) => {
    const docRef = doc(db, collectionName, lineId)
    return setDoc(docRef, payload as any, { merge: true })
  }
  

  return {
    _create,
    get,   
    _update,  
    _set,  
  }
}

export default lineDatabase