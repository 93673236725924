import appStateDatabase from "~/services/database/appState";
import eventDatabase from "~/services/database/event";
import templateDatabase from "~/services/database/template";
import BaseTemplate from "@/assets/baseTemplate";
import _ from 'lodash'

import { useEventStore } from "@/stores/event";
export const useTemplateStore = defineStore("template", {
  state: () => ({
    templates: [],
    templatesHolder: {},
    templateSelected: null,
    unsubScribe: null,
  }),
  getters: {
    getTemplateById: (state) => {
      return (id: string) => {
        return state.templates.find((template) => template.templateId === id);
      };
    },
  },
  actions: {
    fetchTemplateData() {
      const unsubScribe = templateDatabase()._realtimeFetch((templates) => {
        const blackListTemplateId = ["0001:standard24"];
        const allList = templates.filter((template) => {
          if (!template.docId.includes(blackListTemplateId)) {
            return template;
          }
        });


        const filtered = _.filter(allList, (template) => template?.version === '1' || template?.version === '3' )
        this.templates = filtered;
        this.computeHolder(filtered);
      });

      this.unsubScribe = unsubScribe;
    },
    async fetchTemplateSelectedRealtime() {
      const eventStore = useEventStore();
      const unsubScribe = await appStateDatabase()._realtimeFetch(
        eventStore.eventId,
        (template) => {
          this.templateSelected = template;
        }
      );

      this.unsubScribe = unsubScribe;
    },
    computeHolder(_templates) {
      let templateHolder = {
        standard: {},
        remove: {},
        premium: {},
      };

      console.log("starting generate template holder...");
      const templateGroups = ["standard", "premium", "remove"];
      _templates.forEach((template) => {
        templateGroups.forEach((group) => {
          if (template.templateId.includes(group)) {
            templateHolder[group] = {
              ...templateHolder[group],
              [template.templateId]: {
                ...template,
                thumbnail:
                  template.thumbnail ||
                  "https://photowish-organize.s3.ap-southeast-1.amazonaws.com/editor/test_thumbnail_templates/remove01.png",
                selected: false,
              },
            };
          }
        });
      });

      //IF DEMO EVENT RETURN ALL TEMPLATES
      const eventStore = useEventStore();
      if (eventStore.eventId == "demo1") {
        this.templatesHolder = templateHolder;
        return;
      }

      //CHECK ALLOW TEMPLATE
      let newTemplateHolder = {};
      for (let templateType in templateHolder) {
        const templateList = templateHolder[templateType];
        newTemplateHolder[templateType] = {};
        for (let templateKey in templateList) {
          if (BaseTemplate[templateType][templateKey]) {
            newTemplateHolder[templateType][templateKey] = {
              ...newTemplateHolder[templateType],
              ...templateList[templateKey],
            };
          }
        }
      }

      this.templatesHolder = newTemplateHolder;
    },    
    setTemplateSelected(templates) {
      this.templateSelected = templates;
    },
    async updateTemplateSelected(payload: any) {
      const eventStore = useEventStore();
      await eventDatabase()._updateOrRemoveField(eventStore.eventId, payload);
    },
    unSubscribeTemplate() {
      if (!this.unsubScribe) return;
      this.unsubScribe();
    },
  },
});
