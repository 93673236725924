export default {
  standard: {
    standard001pro: true,
    standard002pro: true,
    standard003pro: true,
    standard004pro: true,
    standard005pro: true,
    standard006pro: true,
    standard007pro: true,
    standard008pro: true,
    standard009pro: true,
    standard010pro: true,
    standard011pro: true,
    standard012pro: true,
    standard013pro: true,
    standard014pro: true,
    standard015pro: true,
    standard016pro: true,
    standard017pro: true,
    standard018pro: true,
    standard019pro: true,
    standard020pro: true,
    standard021pro: true,
    standard022pro: true,
    standard023pro: true,
    standard025pro: true,
    standard026pro: true,
    standard027pro: true,
  },
  remove: {
    remove001pro: true,
    remove002pro: true,
    remove003pro: true,
    remove004pro: true,
    remove005pro: true,
    remove006pro: true,
    remove007pro: true,
    remove008pro: true,
    remove009pro: true,
  },
  // standard: {
  //   standard01: true,
  //   standard02: true,
  //   standard03: true,
  //   standard04: true,
  //   standard05: true,
  //   standard06: true,
  //   standard07: true,
  //   standard08: true,
  //   standard09: true,
  //   standard10: true,
  //   standard11: true,
  //   standard12: true,
  //   standard13: true,
  //   standard14: true,
  //   standard15: true,
  //   standard16: true,
  //   standard17: true,
  //   standard18: true,
  //   standard19: true,
  //   standard20: true,
  //   standard21: true,
  //   standard22: true,
  //   standard23: true,
  //   standard25: true,
  //   standard26: true,
  //   standard27: true,
  // },
  // remove: {
  //   remove01: true,
  //   remove02: true,
  //   remove03: true,
  //   remove04: true,
  //   remove05: true,
  //   remove06: true,
  //   remove07: true,
  //   remove08: true,
  //   remove09: true,
  // },
  premium: {},
};
