import { db } from '@/services/firebase/instance'
import { doc,collection, getDocs, setDoc, updateDoc, onSnapshot, Unsubscribe, query, where, deleteDoc } from 'firebase/firestore'
import _ from "lodash"

const promptpayDatabase = () => {
  const collectionName = 'promptpay'


  function _realtimeFetch (eventId: string, callback: (event: Event) => void): Unsubscribe {   
     
   
    const q = query(
      collection(db,`events/${eventId}/promptpay`)      
    );

    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {     
       
      let promptpayList = []
      querySnapshot.forEach((doc) => {    

        const data = doc.data()
        if(data?.status === 'success') {
          promptpayList.push({ id: doc.id, ...doc.data() })    
        }
      }); 

      promptpayList = _.orderBy(promptpayList, ['createAt'], ['desc'])
      callback(promptpayList)
    });
    return unsubscribe
  }

  const _set = async (eventId: string, promptpayId: string, payload: Event) => {
    const docRef = doc(db, "events", eventId, collectionName, promptpayId);
    return setDoc(docRef, payload as any, { merge: true });
  };

  const _remove = (eventId: string, promptpayId: string) => {
    const docRef = doc(db, "events", eventId, collectionName, promptpayId);
    return deleteDoc(docRef);
  };


  return {
    _realtimeFetch,
    _set,
    _remove
  }
}

export default promptpayDatabase