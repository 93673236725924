import { db } from "@/services/firebase/instance";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  Unsubscribe,
  deleteField,
  deleteDoc,
  query,
  orderBy,
  limit,
  startAfter,
  collection,
  getDocs
} from "firebase/firestore";

const wishesDatabase = () => {
  const collectionName = "wishes";
  let lastVisible = null

  const get = async (wishId: string): Promise<Event | null> => {
    const docRef = doc(db, collectionName, wishId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return Object.assign({ docId: docSnap.id }, docSnap.data()) as Event;
    } else {
      return null;
    }
  };

  const _queryWishPagination = async(eventId, isNextPage = false) => {
    const pageLimit = 2
    // Define the initial query or the next query based on the last visible document
    let firstQuery;
    if (isNextPage && lastVisible) {
        firstQuery = query(
            collection(db, `events/${eventId}/${collectionName}`),
            orderBy("timestamp"),
            startAfter(lastVisible),
            limit(pageLimit)
        );
    } else {
        firstQuery = query(
            collection(db, `events/${eventId}/${collectionName}`),
            orderBy("timestamp"),
            limit(pageLimit)
        );
    }

    try {
        const documentSnapshots = await getDocs(firstQuery);

        if (!documentSnapshots.empty) {
            // Get the last visible document
            lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
            console.log("Last visible document:", lastVisible);

            // Process the documents as needed
            documentSnapshots.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
            });

        } else {
            console.log("No more documents to load.");
        }
    } catch (error) {
        console.error("Error getting documents: ", error);
    }
};

  const _update = async (wishId: string, payload: Event) => {
    const docRef = doc(db, collectionName, wishId);
    return updateDoc(docRef, payload as any);
  };

  const _set = async (eventId: string, wishId: string, payload: Event) => {
    const docRef = doc(db, "events", eventId, collectionName, wishId);
    return setDoc(docRef, payload as any, { merge: true });
  };

  const _setSorted = async (eventId: string, subCollName: string, payload: Event) => {
    const docRef = doc(db, "events", eventId, "sorted", subCollName);
    return setDoc(docRef, payload as any, { merge: true });
  };

  const _remove = (eventId: string, wishId: string) => {
    const docRef = doc(db, "events", eventId, collectionName, wishId);
    return deleteDoc(docRef);
  };

  return {
    get,
    _queryWishPagination,
    _update,
    _set,
    _setSorted,
    _remove,
  };
};

export default wishesDatabase;
