import dayjs from "dayjs";
const useDefaultField = () => {
  let eventDate = dayjs().add(1, 'month').format("YYYY-MM-DD")
  const _eventDate = dayjs(eventDate).format("YYYY-MM-DD") 

  const event = {
    register: (user: object, eventId: string) => {
      return {
        eventId: eventId,
        active: true,
        isVisableModal:false,
        eventType: "wedding",
        package: "free",
        createAt: dayjs().format("YYYY-MM-DD"),
        information: {
          lang: "th",
          showRandomWish: true,
          hashtag: "",
          title: "",
          location: "",
          province: "กรุงเทพ",
          date: _eventDate,
          eventTime: "00:00",
          bride: {
            firstName: "",
            lastName: "",
            nickName: "",
          },
          groom: {
            firstName: "",
            lastName: "",
            nickName: "",
          },
          time: {
            "0700": true,
            1800: true,
          },
        },
        templates: {
          "remove001pro": true,
          "remove002pro": true,
          "remove003pro": true,
          "remove004pro": true,
          "standard001pro": true,
          "standard002pro": true,
          "standard003pro": true,
          "standard004pro": true,
        },
        notify: {
          line: {
            [user.userId]: true,
          }
        },
        user: {
          [user.userId]: true,
        },
        book: {
          address: "",
          name: "",
          phone: "",
          date: "",
        },
        company: {},
        promptpay: {
          name: "",
          account: "",
          active: false,
        },
        settings: {
          timer: {
            photos: 15,
            wish: 15,
          },
          show: {
            photos: 4,
            wish: 4,
          },
          writeLimit: 10,
          notify: {
            wishes: true,
            promptpay: true,
          },
          showAllWishes: true,
          welcomeScreen: {
            font: "Callem",
          },
          thankyouScreen: {
            title: "THANK YOU",
            description: 'For Joining Us!'
          },
        },
        userInfo: user,
      };
    },
  };

  const user = {
    register: (profile:object, eventId:string) => {
      return {
        displayName: profile.displayName,
        firstName: profile.displayName,
        lastName: "",
        phoneNumber: "",
        eventList: [eventId],
        admin: false,
        photowishTeam: false,
        email: "",
        line: profile,
        uid: profile.userId,
        eventId: eventId,
        photoURL: profile.pictureUrl || "",
      };
    },
  };

  return { event, user };
};

export default useDefaultField;
