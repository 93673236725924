
import lineDatabase from "~/services/database/line";
import smsDatabase from "~/services/database/sms";
import _ from "lodash"

export const useNotificationStore = defineStore("notification", {
  state: () => ({
    notifyList:[],
    notifyLine: {}
  }),
  actions: {
    async sendToLine(payload:any): Promise<void>{     
        await lineDatabase()._create(payload) 
    },
    async sendToSms( payload:any): Promise<void>{    
        await smsDatabase()._create(payload)  
    },   
    async fetchUserInfoNotify(){
      const userStore = useUserStore();
      const eventStore = useEventStore();
      const notifyObjList = eventStore.event?.notify?.line
      
      let list = []
      if(notifyObjList){
        for(let key in notifyObjList){           
          if(notifyObjList[key]){
            let user = await userStore.getUserById(key)
            list.push({...user, notifyStatus: notifyObjList[key]})          
          }
            
        }
      }     

      this.notifyList =  _.orderBy(list, ['displayName', 'asc'])
      this.notifyLine = notifyObjList

    },         
   
  },
});
