import liff from "@line/liff";
import axios from 'axios'

// const LIFF_ID = "2004940069-x2jo0BPp";
// const LIFF_ID = "2004940069-RgEBgzL9"; //PROD
const LIFF_ID = "2004940069-mw39pE16"; //PARTNER

const useLine = () => {
  async function init(liffId?:string) {
    if(liffId){
      await liff.init({ liffId: liffId });
    }else{
      await liff.init({ liffId: LIFF_ID });
    }
    
    return liff
  }

  async function getProfile() {
    return await liff.getProfile();
  }

  function isLoggedIn() {
    if (liff.isLoggedIn()) {
      return true;
    }

    return false;
  }

  async function getFriendship() {
    const friendShip = await liff.getFriendship();
    if (friendShip.friendFlag) {
      return true;
    }
    return false;
  }

  function isInClient(){
    return  liff.isInClient();
  }

  async function lineInitForInvite(inviteMode: string) {
    const userStore = useUserStore();
    const authStore = useAuthStore();
    try {
      const liff = await init()

      if (liff.isLoggedIn()) {

        authStore.line = await liff.getProfile() 

        //INVITE
        if (authStore.line.userId) {
          await userStore.setInviteUser(inviteMode);
        } 

      } else {     
        const eventId = localStorage.getItem("invite_eventId");
        if (eventId) {
          liff.login();
        }
      }
    } catch (error) {
      console.error("Error during LIFF initialization:", error);
      alert(error)
      liff.logout();
      

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }     
  }

  async function scanCode(){
    return await liff.scanCodeV2();
  }

  async function logout(){
    const liff = await init()
    return await liff.logout();
  }

  return {
    init,
    isInClient,
    getProfile,
    isLoggedIn,
    getFriendship,
    lineInitForInvite,
    scanCode,
    logout
  };
};

export default useLine;
